import { DndContext, type DragEndEvent } from "@dnd-kit/core";
import { api } from "~/server/trpc/react";
import { useTeamAccount } from "./TeamAccountProvider";
import { useLists } from "./ListsProvider";
import { useFolders } from "./FolderProvider";
import { useState } from "react";

const DndProvider = ({ children }: { children: React.ReactNode }) => {
  const { handleRefetch: handleRefetchLists } = useLists();
  const { handleRefetch: handleRefetchFolders } = useFolders();
  const { mutate: folderOrFileMove } = api.folder.folderOrFileMove.useMutation({
    onSuccess: () => {
      handleRefetchLists();
      handleRefetchFolders();
    },
  });
  const { activeTeamAccount } = useTeamAccount();
  const [clickDisabled, setClickDisabled] = useState(false);
  function handleDragStart() {
    setTimeout(() => {
      setClickDisabled(true);
    }, 250);
  }
  function handleDragEnd(event: DragEndEvent) {
    if (
      typeof event.over?.id === "string" ||
      typeof event.active.id === "string"
    ) {
      const recievingId = event.over?.id as string;
      const sendingId = event.active.id as string;
      const type = event.active.data.current?.type;
      setClickDisabled(true);
      folderOrFileMove({
        teamAccountId: activeTeamAccount.id,
        id: sendingId,
        type,
        parentFolderId: recievingId,
      });
    }
    setTimeout(() => {
      setClickDisabled(false);
    }, 250);
  }
  return (
    <DndContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
      <div className={`w-full ${clickDisabled ? "pointer-events-none" : ""}`}>
        {children}
      </div>
    </DndContext>
  );
};

export default DndProvider;
