"use client";
import { ListsProvider } from "~/providers/ListsProvider";
import { LoggerProvider } from "~/providers/LoggerProvider";
import { SidebarProvider } from "~/components/ui/sidebar";
import { TeamAccountProvider } from "~/providers/TeamAccountProvider";
import { TeamAccountUserProvider } from "~/providers/TeamAccountUserProvider";
import ActivityTracker from "~/components/ActivityTracking";
import { FoldersProvider } from "~/providers/FolderProvider";
import DndProvider from "~/providers/DndProvider";
import { usePathname } from "next/navigation";
import { Toaster } from "~/components/ui/toaster";

// This is the main component that wraps the entire app, since <SessionProvider> must be within a client component, which layout.tsx is not.
export default function Main({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  const pathname = usePathname();
  if (pathname === "/custom") {
    return children;
  }
  return (
    <>
      <TeamAccountUserProvider>
        <LoggerProvider>
          <TeamAccountProvider>
            <ListsProvider>
              <FoldersProvider>
                <ActivityTracker />
                <SidebarProvider>
                  <DndProvider>{children}</DndProvider>
                </SidebarProvider>
              </FoldersProvider>
            </ListsProvider>
          </TeamAccountProvider>
        </LoggerProvider>
      </TeamAccountUserProvider>
      <Toaster />
    </>
  );
}
